<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="3"/>
        <b-col cols="6">
          <SubHeader>
            <b-row class="mt-2 mb-1">
              <b-col cols="3">
                <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Dienstversion..."/>
              </b-col>
            </b-row>
          </SubHeader>
          <b-list-group :class="listGroupClasses" style="font-size: 14px">
            <b-row cols="1">
              <b-col>
                <b-list-group-item v-for="service in serviceList" :key="service.id" class="list-row flex-fill">
                  <Service :service="service"/>
                </b-list-group-item>
              </b-col>
            </b-row>
          </b-list-group>
        </b-col>
        <b-col cols="3"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import {getRequest} from "@/modules/requests";
import ScrollToTop from "@/components/utility/ScrollToTop";
import Service from "@/components/lists/serviceList/Service";
import SubHeader from "@/components/structure/SubHeader";

export default {
  name: "ServiceList",
  components: {
    SubHeader,
    ScrollToTop,
    Service
  },
  data() {
    return {
      search: '',
      services: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getServices() {
      this.setLoadingState(true)
      await getRequest('service-versions', null, this)
          .then((response) => {
            this.services = response.data
          })
      this.setLoadingState(false)
    },
  },
  computed: {
    ...mapGetters(['userThemeId']),
    listGroupClasses() {
      if(this.userThemeId === 2) {
        return ['mt-2']
      }
      return ['text-white', 'mt-2']
    },
    serviceList() {
      return _.filter(this.services, (entry) => {
        if(entry.version.toLowerCase().includes(this.search.toLowerCase())) {
          return true
        }
      })
    }
  },
  created() {
    this.getServices();
  }
}
</script>

<style lang="scss">
</style>
